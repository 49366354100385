// extracted by mini-css-extract-plugin
export var afterCaption = "maine_digest_2022_01-module--afterCaption--8d7dd";
export var columnWrapper = "maine_digest_2022_01-module--columnWrapper--97928";
export var dateline = "maine_digest_2022_01-module--dateline--470bb";
export var heading = "maine_digest_2022_01-module--heading--0ddda";
export var headingLogo = "maine_digest_2022_01-module--headingLogo--b0eac";
export var headingWrapper = "maine_digest_2022_01-module--headingWrapper--810a4";
export var heroImage = "maine_digest_2022_01-module--heroImage--3ea24";
export var instance = "maine_digest_2022_01-module--instance--83404";
export var latestnewsarticleheadline = "maine_digest_2022_01-module--latestnewsarticleheadline--303e3";
export var maineDigest = "maine_digest_2022_01-module--maineDigest--91830";
export var photo = "maine_digest_2022_01-module--photo--368de";
export var photoCaption = "maine_digest_2022_01-module--photoCaption--6e937";
export var photosWrapper = "maine_digest_2022_01-module--photosWrapper--d7e2d";
export var rightCaptions = "maine_digest_2022_01-module--rightCaptions--e43de";
export var rightPhoto = "maine_digest_2022_01-module--rightPhoto--edbb9";
export var rightPhotoCaption = "maine_digest_2022_01-module--rightPhotoCaption--18b06";
export var subheading = "maine_digest_2022_01-module--subheading--9b8f9";
export var textWrapper = "maine_digest_2022_01-module--textWrapper--921aa";
export var whatsNew = "maine_digest_2022_01-module--whatsNew--169f5";